import { GET_ALL_SUBSCRIPTIONS,GET_PUNTER_SUBSCRIPTIONS,
  CANCEL_SUBSCRIPTION,CREATE_SUBSCRIPTION, FETCH_HISTORY } from "../types/subscriptionTypes";

import { LOGOUT_USER } from "../types/authTypes";

const INITIAL_STATE = {
  data:[],
  punter: {
    created_on: "2021-12-07T00:17:37.541Z",
    description: "Default Mookie Free Plan",
    id: 15,
    modified_on: "2021-12-07T00:17:37.541Z",
    monthly_amount: "0.00",
    name: "FREE PLAN",
    no_of_bookmakers: 2,
    weekly_amount: "0.00",
    yearly_amount: "0.00",
    coinsProvision: 0,
  },
  history: [],
};

const subscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_PUNTER_SUBSCRIPTIONS:
      return {
        ...state,
        punter: action.payload,
      };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        // punter: {},
      };
    case CREATE_SUBSCRIPTION:
      return{
        ...state,
        punter: action.payload
      };
    case FETCH_HISTORY:
      return{
        ...state,
        history: action.payload
      };

    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default subscriptionReducer;