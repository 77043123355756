// reducers/sseReducer.js
import { LOGOUT_USER } from "../types/authTypes";
import { UPDATE_SCROLL_STATE } from "../types/scrollTypes";

const initialState = {
  isScrolling: false,
  scrollPosition: 0,
  scrollDirection: null,
};

const scrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCROLL_STATE:
      return {
        ...state,
        isScrolling: action.payload.isScrolling,
        scrollPosition: action.payload.scrollPosition,
        scrollDirection: action.payload.scrollDirection,
      };

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
};

export default scrollReducer;
