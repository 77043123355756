import { sseEventReceived } from '../redux/actions/sseAction';

class SSEService {
  constructor(dispatch) {
    this.eventSource = null;
    this.dispatch = dispatch;
    this.retryInterval = 5000; // Initial retry interval in milliseconds
  }

  establishConnection(token) {
    const sourceURL = `https://mookie-api.herokuapp.com/sse?token=${token}`;

    // Close existing connection if it exists
    if (this.eventSource && this.eventSource.url !== sourceURL) {
      this.closeConnection();
    }

    // Establish a new SSE connection
    if (!this.eventSource || this.eventSource.readyState === EventSource.CLOSED) {
      this.createEventSource(sourceURL);
    }

    // Add event listeners
    this.eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      this.dispatch(sseEventReceived(eventData));
    };

    this.eventSource.onerror = (error) => {
      console.error('SSE connection error:', error);
      this.retryConnection(token);
    };
  }

  createEventSource(sourceURL) {
    this.eventSource = new EventSource(sourceURL, { headers: { "Content-Type": "text/event-stream" } });
  }

  closeConnection() {
    if (this.eventSource) {
      //console.log("Closing connection");
      this.eventSource.close();
    }
  }

  retryConnection(token) {
    //console.log(`Retrying SSE connection in ${this.retryInterval} milliseconds...`);
    
    setTimeout(() => {
      this.establishConnection(token);
      // Exponential backoff: Double the retry interval for the next attempt
      this.retryInterval *= 2;
    }, this.retryInterval);
  }
}

export default SSEService;
