export const GET_ALL_BOOKMAKERS = 'GET_ALL_BOOKMAKERS';
export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS';
export const ADD_BOOKING = 'ADD_BOOKING';
export const ADD_BOOKING2 = 'ADD_BOOKING2';
export const PRE_SAVE_BOOKING = 'PRE_SAVE_BOOKING';
export const CANCEL_SAVE_BOOKING = 'CANCEL_SAVE_BOOKING';
export const REMOVE_BOOKING = 'REMOVE_BOOKING';
export const REMOVE_BOOKING2 = 'REMOVE_BOOKING2';
export const LOAD_CONVERT_BOOKING = 'LOAD_CONVERT_BOOKING';
export const SUCCESS_CONVERT_BOOKING = 'SUCCESS_CONVERT_BOOKING';
export const FAIL_CONVERT_BOOKING = 'FAIL_CONVERT_BOOKING';
export const FAILED_ADD_BOOKING_VIA_OCR = "FAILED_ADD_BOOKING_VIA_OCR";
export const SORT_BOOKINGS = "SORT_BOOKINGS";
export const IS_BOOKING_LOADING_FALSE = "IS_BOOKING_LOADING_FALSE";
export const IS_BOOKING_LOADING_TRUE = "IS_BOOKING_LOADING_TRUE";
export const FETCH_BOOKING_DETAILS = "FETCH_BOOKING_DETAILS";
export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const ACTIVE_COUNT = "ACTIVE_COUNT";
export const GET_GAMES_ANALYTICS = "GET_GAMES_ANALYTICS";
export const GET_BOOKING_ANALYTICS = "GET_BOOKING_ANALYTICS";
export const GET_COMMUNITY_BOOKING_ANALYTICS = "GET_COMMUNITY_BOOKING_ANALYTICS";
export const GET_COMMUNITY_GAMES_ANALYTICS = "GET_COMMUNITY_GAMES_ANALYTICS";
export const PRE_SAVE_EVENTS = "PRE_SAVE_EVENTS";
export const LOAD_REFRESH_BOOKING = "LOAD_REFRESH_BOOKING";
export const SUCCESS_REFRESH_BOOKING = "SUCCESS_REFRESH_BOOKING";
export const FAIL_REFRESH_BOOKING = "FAIL_REFRESH_BOOKING";
export const MANAGE_ACTIVE_BOOKING = "MANAGE_ACTIVE_BOOKING";