import logger from "redux-logger";
import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk'; // Import Redux Thunk

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['auth','booking','others']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Modify the applyMiddleware function to include Redux Thunk
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk, logger) // Include Redux Thunk and logger
);

let persistor = persistStore(store);

export default store;
