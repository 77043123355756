import { toastError } from "../components/ui/Toast";
import { signOut } from "../redux/actions/authAction";
import { NETWORK_ERROR_OCCURRED } from "../redux/types/authTypes";

let errorQueue = [];
let isProcessingError = false;
let uniqueErrors = new Set();

let errorResetTimer = null;

const processErrorQueue = () => {
  if (!errorQueue.length || isProcessingError) return;

  const errorMessage = errorQueue.shift();
  isProcessingError = true;
  toastError(errorMessage);
  setTimeout(() => {
    isProcessingError = false;
    processErrorQueue();
  }, 3000);
};

// Function to clear the error queue after a specified duration if no new errors are added
const resetErrorQueue = () => {
  errorQueue = [];
  uniqueErrors.clear();
};

const handleError = (err, dispatch) => {
  let errorMessage;

  if (err && err.response) {
    if (err.response.status === 500 || err.response.status === 502) {
      errorMessage = "It's not you, it's us. Please try again after some time";
    } else if (err.response.status === 401) {
      if (dispatch) signOut()(dispatch);
      return;
    } else if (err.response.status === 304) {
      errorMessage = "Sorry, you can only reset your passcode once.";
    } else {
      errorMessage =
        err.response.data?.message ||
        err.response.data?.error ||
        err.response.data?.err ||
        (err.response.data?.data && err.response.data.data?.responseMessage);
    }
  } else if (err.message === "Internal Sever Error") {
    errorMessage = "It's not you, it's us. Please try again after some time";
  } else if(err.message === "Network Error" ){
    errorMessage = "Network error occurred. Please check your internet connection and try again.";
    if (dispatch) 
    dispatch({ type: NETWORK_ERROR_OCCURRED });
  }

  if (errorMessage && !uniqueErrors.has(errorMessage)) {
    uniqueErrors.add(errorMessage);
    errorQueue.push(errorMessage);
    processErrorQueue();

    // Reset the error queue after 10 seconds if no new error is added
    clearTimeout(errorResetTimer);
    errorResetTimer = setTimeout(resetErrorQueue, 10000); // 10 seconds in milliseconds
  }
};

export default handleError;
