import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import useScrollPosition from './hooks/useScrollPosition';
// import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { signOut } from "./redux/actions/authAction";
import "react-toastify/dist/ReactToastify.min.css";
import Routes from "./config/routes";
import "./styles/main.css";
import "./App.scss";
// import markerSDK from "@marker.io/browser";
import { useMediaQuery } from 'react-responsive';
import rotate_device from '../src/assets/rotate-device.png'
import Bowser from 'bowser';

function App() {

  const dispatch = useDispatch();
  const logout = () => {
    signOut()(dispatch);
  };

  //useScrollPosition();

  // Define events to monitor user activity
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
    "wheel",
    "touchstart",
    "touchmove", 
    "touchend",
  ];

  let timer;

  // Function to handle the logout timer
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      logout();
    }, 18000000); // 5 hours timeout
  };

  // Function to reset the logout timer
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
    handleLogoutTimer();
  };

  // Add event listeners to monitor user activity
  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };

    // events.forEach((event) => {
    //   window.addEventListener(event, handleUserActivity, {
    //     passive: true,
    //   });
    // });

    handleLogoutTimer();

    // Cleanup function
    return () => {
      // events.forEach((event) => {
      //   window.removeEventListener(event, handleUserActivity);
      // });
      clearTimeout(timer);
    };
  }, []);

  // Load Marker.io widget on component mount
  // useEffect(() => {
  //   const loadMarkerWidget = async () => {
  //     await markerSDK.loadWidget({
  //       project: "65f476b37a8285669c6d0548",
  //     });
  //   };

  //   loadMarkerWidget();

  //   // Cleanup function
  //   return () => {
  //     // Perform any cleanup if necessary
  //   };
  // }, []);

  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isMobile = () => /Mobi|Android|iPhone|iPod/.test(navigator.userAgent) || browser.satisfies({ mobile: true });
  
  const [showMessage, setShowMessage] = useState(false);

  // Handle setting the show message state with an effect
  useEffect(() => {
    if (isMobile() && isLandscape) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [isLandscape]); // Re-run effect when landscape changes

  return (
    <div className="App">
      <div className={`rotate-device-message ${showMessage ? 'show' : ''}`}>
        <img src={rotate_device} height={100} width={100} alt="Rotate Device Icon" />
        <p className="mt-4">Please rotate your Device</p>
      </div>
      {!showMessage && <Routes />}
      {/*<TawkMessengerReact
            propertyId="60e1aaa6d6e7610a49a98798"
            widgetId="1f9oo56t6"
            useRef={tawkMessengerRef}
      />*/}
    </div>
  );
}

export default App;
