// reducers/sseReducer.js
import { LOGOUT_USER } from "../types/authTypes";

const initialState = {
  events: [], // Store SSE events here
  notifications: {}, // Store notifications by ID
  all_notifications_count : 0,
  pending_notifications_count : 0,
  hasNextPage: false,
};

const sseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SSE_EVENT_RECEIVED':
      return {
        ...state,
        events: [...state.events, action.payload],
      };

    case 'GET_NOTIFICATION_BY_ID_SUCCESS':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.id]: action.payload,
        },
      };

    case 'GET_NOTIFICATIONS_BY_RECIPIENT_SUCCESS':
      const { notifications, totalCount, pendingTotalCount, hasNextPage } = action.payload.data;
    
      const notificationsById = notifications.reduce((acc, notification) => {
        acc[notification.id] = notification;
        return acc;
      }, {});
    
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...notificationsById,
        },
        all_notifications_count: totalCount || state.all_notifications_count,
        pending_notifications_count: pendingTotalCount || state.pending_notifications_count,
        hasNextPage: Boolean(hasNextPage) || state.hasNextPage,
      };
    
    case 'GET_NOTIFICATIONS_BY_RECIPIENT_FAILURE':
      
      return {
        ...state,
        hasNextPage: false,
      };
      

    case 'UPDATE_NOTIFICATION_STATUS_SUCCESS':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.id]: {
            ...state.notifications[action.payload.id],
            status: action.payload.status,
          },
        },
      };

    case 'MARK_ALL_AS_READ':
      // Mark all notifications as READ
      const updatedNotifications = Object.keys(state.notifications).reduce((acc, id) => {
        acc[id] = {
          ...state.notifications[id],
          status: 'READ',
        };
        return acc;
      }, {});

      return {
        ...state,
        notifications: updatedNotifications,
      };

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
};

export default sseReducer;
