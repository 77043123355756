import { GET_XP_LEADERBOARD_WEEK, GET_XP_LEADERBOARD_MONTH, GET_XP_LEADERBOARD_CHANNEL } from "../types/dashboardTypes";
import { LOGOUT_USER } from "../types/authTypes";

const INITIAL_STATE = {
  experience_leaderboard: {
    week: [],
    month: [],
  },
  experience_leaderboard_by_channel: {}
};

const dashboardReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case GET_XP_LEADERBOARD_WEEK:
      return {
        ...state,
        experience_leaderboard: { ...state.experience_leaderboard, week: action.payload }
      }; 
    case GET_XP_LEADERBOARD_MONTH:
      return {
        ...state,
        experience_leaderboard: { ...state.experience_leaderboard, month: action.payload }
      }; 
    case GET_XP_LEADERBOARD_CHANNEL:
      return {
          ...state,
          experience_leaderboard_by_channel: {
              ...state.experience_leaderboard_by_channel,
              [action.payload.channelId]: {
                  ...state.experience_leaderboard_by_channel[action.payload.channelId],
                  [action.payload.duration]: action.payload.data
              }
          }
      };
    
    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default dashboardReducer;