export const capitalize = (str) => {
  if (str) {
    str = str.split(" ");
    for (let i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
  }
};

const flagUrls = {
    'Albania': 'al',
    'Andorra': 'ad',
    'Armenia': 'am',
    'Austria': 'at',
    'Azerbaijan': 'az',
    'Belarus': 'by',
    'Belgium': 'be',
    'Bosnia and Herzegovina': 'ba',
    'Bulgaria': 'bg',
    'Croatia': 'hr',
    'Cyprus': 'cy',
    'Czech Republic': 'cz',
    'Denmark': 'dk',
    'Estonia': 'ee',
    'Finland': 'fi',
    'France': 'fr',
    'Georgia': 'ge',
    'Germany': 'de',
    'Greece': 'gr',
    'Hungary': 'hu',
    'Iceland': 'is',
    'Ireland': 'ie',
    'Italy': 'it',
    'Kazakhstan': 'kz',
    'Kosovo': 'xk',
    'Latvia': 'lv',
    'Liechtenstein': 'li',
    'Lithuania': 'lt',
    'Luxembourg': 'lu',
    'Malta': 'mt',
    'Moldova': 'md',
    'Monaco': 'mc',
    'Montenegro': 'me',
    'Netherlands': 'nl',
    'North Macedonia': 'mk',
    'Norway': 'no',
    'Poland': 'pl',
    'Portugal': 'pt',
    'Romania': 'ro',
    'Russia': 'ru',
    'San Marino': 'sm',
    'Serbia': 'rs',
    'Slovakia': 'sk',
    'Slovenia': 'si',
    'Spain': 'es',
    'Sweden': 'se',
    'Switzerland': 'ch',
    'Turkey': 'tr',
    'Ukraine': 'ua',
    'United Kingdom': 'gb',
    'Vatican City': 'va',
    'Wales': 'gb-wls',
    'Afghanistan': 'af',
    'Algeria': 'dz',
    'American Samoa': 'as',
    'Angola': 'ao',
    'Antigua and Barbuda': 'ag',
    'Argentina': 'ar',
    'Australia': 'au',
    'Bahamas': 'bs',
    'Bahrain': 'bh',
    'Bangladesh': 'bd',
    'Barbados': 'bb',
    'Belize': 'bz',
    'Benin': 'bj',
    'Bermuda': 'bm',
    'Bhutan': 'bt',
    'Bolivia': 'bo',
    'Botswana': 'bw',
    'Brazil': 'br',
    'British Virgin Islands': 'vg',
    'Brunei': 'bn',
    'Burkina Faso': 'bf',
    'Burundi': 'bi',
    'Cabo Verde': 'cv',
    'Cambodia': 'kh',
    'Cameroon': 'cm',
    'Canada': 'ca',
    'Central African Republic': 'cf',
    'Chad': 'td',
    'Chile': 'cl',
    'China': 'cn',
    'Colombia': 'co',
    'Comoros': 'km',
    'Congo': 'cg',
    'Congo (Democratic Republic)': 'cd',
    'Cook Islands': 'ck',
    'Costa Rica': 'cr',
    'Côte d\'Ivoire': 'ci',
    'Cuba': 'cu',
    'Djibouti': 'dj',
    'Dominica': 'dm',
    'Dominican Republic': 'do',
    'East Timor': 'tl',
    'Ecuador': 'ec',
    'Egypt': 'eg',
    'El Salvador': 'sv',
    'Equatorial Guinea': 'gq',
    'Eritrea': 'er',
    'Eswatini': 'sz',
    'Ethiopia': 'et',
    'Fiji': 'fj',
    'Gabon': 'ga',
    'Gambia': 'gm',
    'Ghana': 'gh',
    'Great Britain': 'gb',
    'Grenada': 'gd',
    'Guam': 'gu',
    'Guatemala': 'gt',
    'Guinea': 'gn',
    'Guinea-Bissau': 'gw',
    'Guyana': 'gy',
    'Haiti': 'ht',
    'Honduras': 'hn',
    'Hong Kong': 'hk',
    'India': 'in',
    'Indonesia': 'id',
    'Iran': 'ir',
    'Iraq': 'iq',
    'Israel': 'il',
    'Jamaica': 'jm',
    'Japan': 'jp',
    'Jordan': 'jo',
    'Kenya': 'ke',
    'Kiribati': 'ki',
    'Kuwait': 'kw',
    'Kyrgyzstan': 'kg',
    'Laos': 'la',
    'Lebanon': 'lb',
    'Lesotho': 'ls',
    'Liberia': 'lr',
    'Libya': 'ly',
    'Madagascar': 'mg',
    'Malawi': 'mw',
    'Malaysia': 'my',
    'Maldives': 'mv',
    'Mali': 'ml',
    'Marshall Islands': 'mh',
    'Mauritania': 'mr',
    'Mauritius': 'mu',
    'Mexico': 'mx',
    'Micronesia': 'fm',
    'Mongolia': 'mn',
    'Morocco': 'ma',
    'Mozambique': 'mz',
    'Myanmar': 'mm',
    'Namibia': 'na',
    'Nauru': 'nr',
    'Nepal': 'np',
    'New Zealand': 'nz',
    'Nicaragua': 'ni',
    'Niger': 'ne',
    'Nigeria': 'ng',
    'North Korea': 'kp',
    'Oman': 'om',
    'Pakistan': 'pk',
    'Palau': 'pw',
    'Palestine': 'ps',
    'Panama': 'pa',
    'Papua New Guinea': 'pg',
    'Paraguay': 'py',
    'Peru': 'pe',
    'Philippines': 'ph',
    'Puerto Rico': 'pr',
    'Qatar': 'qa',
    'Rwanda': 'rw',
    'Saint Kitts and Nevis': 'kn',
    'Saint Lucia': 'lc',
    'Saint Vincent and the Grenadines': 'vc',
    'Samoa': 'ws',
    'São Tomé and Príncipe': 'st',
    'Saudi Arabia': 'sa',
    'Senegal': 'sn',
    'Seychelles': 'sc',
    'Sierra Leone': 'sl',
    'Singapore': 'sg',
    'Solomon Islands': 'sb',
    'Somalia': 'so',
    'South Africa': 'za',
    'South Korea': 'kr',
    'South Sudan': 'ss',
    'Sri Lanka': 'lk',
    'Sudan': 'sd',
    'Suriname': 'sr',
    'Syria': 'sy',
    'Taiwan': 'tw',
    'Tajikistan': 'tj',
    'Tanzania': 'tz',
    'Thailand': 'th',
    'Togo': 'tg',
    'Tonga': 'to',
    'Trinidad and Tobago': 'tt',
    'Tunisia': 'tn',
    'Turkmenistan': 'tm',
    'Tuvalu': 'tv',
    'Uganda': 'ug',
    'United Arab Emirates': 'ae',
    'United States': 'us',
    'Uruguay': 'uy',
    'Uzbekistan': 'uz',
    'Vanuatu': 'vu',
    'Venezuela': 've',
    'Vietnam': 'vn',
    'Yemen': 'ye',
    'Zambia': 'zm',
    'Zimbabwe': 'zw'
};

export const getCountryLogo = (country) => {
    const normalizeCountryName = (country) => {
        const normalized = country.split(/\s|-/)[0];
        if (country.toLowerCase().includes("bosnia")) return 'Bosnia and Herzegovina';
        if (country.toLowerCase().includes("czech")) return 'Czech Republic';
        if (country.toLowerCase().includes("north macedonia")) return 'North Macedonia';
        if (country.toLowerCase().includes("united kingdom") || country.toLowerCase().includes("england")) return 'United Kingdom';
        if (country.toLowerCase().includes("vatican")) return 'Vatican City';
        return normalized;
    };

    const normalizedCountry = normalizeCountryName(country);
    const flagCode = flagUrls[country] || flagUrls[normalizedCountry];

    if (flagCode) {
        return `https://flagicons.lipis.dev/flags/4x3/${flagCode}.svg`;
    }

    // Check if any key matches part of the country name
    for (const [key, code] of Object.entries(flagUrls)) {
        if (country.toLowerCase().includes(key.toLowerCase())) {
            return `https://flagicons.lipis.dev/flags/4x3/${code}.svg`;
        }
    }

    // If no match is found, return null
    return null;
};


export const shortNumFormat = (number) => {
    // Define the threshold for displaying in shorthand notation
    const threshold = 1000;

    // Check if the number is greater than or equal to the threshold
    if (number >= threshold) {
        // Calculate the appropriate multiplier (k for thousands, m for millions, etc.)
        const units = ['', 'k', 'm', 'b', 't'];

        // Calculate the index of the appropriate multiplier
        const multiplierIndex = Math.floor(Math.log10(number) / Math.log10(threshold));

        // Calculate the formatted value
        let formattedValue = (number / Math.pow(threshold, multiplierIndex)).toString();

        // Ensure two decimal places without rounding
        const decimalIndex = formattedValue.indexOf('.');
        if (decimalIndex !== -1) {
            formattedValue = formattedValue.substring(0, decimalIndex + 3);
        }

        // Return the formatted value with the multiplier
        return `${formattedValue}${units[multiplierIndex]}`;
    }

    // If the number is smaller than the threshold, return it as is
    return number;
};

export const formatNumberWithCommas = (value) => {
    if (typeof value === 'string') {
        value = parseFloat(value.replace(/,/g, ''));
        if (isNaN(value)) {
            throw new Error('Input string is not a valid number');
        }
    } else if (typeof value !== 'number') {
        throw new Error('Input must be a number or a string representing a number');
    }

    // Determine the number of decimal places to use
    const decimalPlaces = value % 1 === 0 ? 0 : 2;
    
    return value.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
}

export const isEmpty = (obj) => obj == null || Object.keys(obj).length === 0;

export const currency = (value) => new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
}).format(value);

export const deepEqual = (x, y) => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty && x.constructor === y.constructor ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

export const deepEqual2 = (obj1, obj2) => {
    // If both objects are not objects, compare them directly
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 === obj2;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, objects are not equal
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys in obj1 have the same value as in obj2 recursively
    for (const key of keys1) {
        if (!deepEqual2(obj1[key], obj2[key])) {
            return false;
        }
    }

    // If all checks passed, objects are equal
    return true;
}

export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const generateUrl = (query, object) => {
    const transformedObject = Object.entries(object);
    let queryCopy = new URL(query);

    transformedObject.forEach(element => {
        if(element[1]=== ""){
            return
        } else {
            queryCopy.searchParams.append(element[0], element[1]);
        }
    });
    
    return queryCopy.href;
};

export const logoSwitch = (id) => {
    switch (id) {
        case 38:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1661279869/bookie%20logo/Best_Odds_1_rhm4mk.png"
        case 26:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646118818/22bet_otqa2n.svg"
        case 25:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646063240/lionsbet_cnf0sx.svg"
        case 24:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646063626/betbiga_ek7tqd.svg"
        case 23:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646046146/nairabet_mnrzt6.svg"
        case 22:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646046146/melbet_v5nxkc.svg"
        case 21:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646046146/betking_alhose.svg"
        case 20:
        case 73:
          return "https://res.cloudinary.com/mookie-admin/image/upload/v1646063245/sportybet_eyl8k8.svg"
        case 19:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646047855/MerryBet_gafq3p.svg"
        case 18:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646047812/1xBET_bl2jmk.svg"
        case 17:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646047848/Bet9ja_mewswq.svg"
        case 75:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1699457620/afilliate_logos/betway_aff_logo_qmfdee.png"
        default:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1638967567/b296a239054db5b62e7d94d1712fdac3_1_kdy6a0.png";
    }
}
export const smallLogoSwitch = (id,url) => {
    switch (id) {
        case 38:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1661279869/bookie%20logo/Best_Odds_1_rhm4mk.png"
        case 26:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884586/bookie%20logo/22_1_zwdcnv.png"
        case 25:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884586/bookie%20logo/LB_1_ymhhbw.png"
        case 24:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884306/bookie%20logo/BBiga_1_kibjol.png"
        case 23:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884586/bookie%20logo/nairabet_1_prxmbm.png"
        case 22:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884306/bookie%20logo/2865fa_1_tbkq25.png"
        case 21:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884306/bookie%20logo/betking-logo-bk_1_cicaaa.png"
        case 20:
        case 73:
          return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884586/bookie%20logo/sportybet_1_rjr3e0.png"
        case 19:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884586/bookie%20logo/Group_6_cfs4tp.png"
        case 18:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884306/bookie%20logo/b296a239054db5b62e7d94d1712fdac3_1_xrl3yn.png"
        case 17:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1649884306/bookie%20logo/bet9ja_logo_transp_1_tvhbw0.png"
        case 78:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1699457620/afilliate_logos/betway_aff_logo_qmfdee.png"
        case 74:
            return "https://res.cloudinary.com/mookie-admin/image/upload/v1646047812/1xBET_bl2jmk.svg"
        default:
            return url;
    }
}

export const bookieSwitch = (id) => {
  switch (id) {
      case 26:
          return "22bet"
      case 25:
          return "lionsbet"
      case 24:
          return "betbiga"
      case 23:
          return "nairabet"
      case 22:
          return "melbet"
      case 21:
          return "betking"
      case 20:
      case 73:
        return "sportybet"
      case 19:
          return "merryBet"
      case 74:
      case 18:
          return "1xbet"
      case 17:
          return "bet9ja"
      case 78:
          return "betway"
      default:
          return "N/A";
  }
}

export const bookieData = [
  {name: "bet9ja", id: 17, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646047848/Bet9ja_mewswq.svg", iSrc: "https://sports.bet9ja.com/", slug: "bet9ja", compatible: true, address: "Tomi's House, 9 Funsho Williams Avenue, By Fire Service Station, Ojuelegba Bus Stop, Lagos.", email: "cs@mybet9ja.com"},
  {name: "1xbet", id: 18, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646047812/1xBET_bl2jmk.svg", iSrc: "https://1xbet.ng/en/", slug: "1xbet", compatible: true},
  {name: "MerryBet", id: 19, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646047855/MerryBet_gafq3p.svg", iSrc: "https://www.merrybet.com/sports", slug: "merrybet", compatible: true},
  {name: "Sportybet", id: 20, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646063245/sportybet_eyl8k8.svg", iSrc: "https://www.sportybet.com/ng/", slug: "sportybet", compatible: false},
  {name: "Sportybet", id: 73, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646063245/sportybet_eyl8k8.svg", iSrc: "https://www.sportybet.com/ng/", slug: "sportybet", compatible: false},
  {name: "BetKing", id: 21, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646046146/betking_alhose.svg", iSrc: "https://www.betking.com/sports/s", slug: "betking", compatible: false},
  {name: "Melbet", id: 22, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646046146/melbet_v5nxkc.svg", iSrc: "https://melbet.ng/", slug: "melbet", compatible: false},
  {name: "Nairabet", id: 23, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646046146/nairabet_mnrzt6.svg", iSrc: "https://www.nairabet.com/", slug: "nairabet", compatible: false},
  {name: "Betbiga", id: 24, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646063626/betbiga_ek7tqd.svg", iSrc: "https://www.betbiga.com/en/betting?gclid=CjwKCAiAgvKQBhBbEiwAaPQw3OYYNtKAMJb1_AlUhFpruZXsOujpwYU_MgWIAQwuF1OfblZGn5jLWRoCtGAQAvD_BwE", slug: "betbiga", compatible: true},
  {name: "LionsBet", id: 25, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646063240/lionsbet_cnf0sx.svg", iSrc: "https://www.lionsbet.com/ng/", slug: "lionsbet", compatible: true},
  {name: "22Bet", id: 26, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1646118818/22bet_otqa2n.svg", iSrc: "https://22bet.ng/", slug: "22bet", compatible: false},
  {name: "Betway", id: 75, imgSrc:"https://res.cloudinary.com/mookie-admin/image/upload/v1699457620/afilliate_logos/betway_aff_logo_qmfdee.png", iSrc: "https://22bet.ng/", slug: "22bet", compatible: false},
];

export const avatars = [
    {id: 1, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652488208/avatars/Flat_Icons_2_stbexz.svg"},
    {id: 2, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652487973/avatars/Flat_Icons_3_l23pyz.svg"},
    {id: 3, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652487974/avatars/Flat_Icons_1_a1s61l.svg"},
    {id: 4, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652487974/avatars/Flat_Icons_5_bvml9z.svg"},
    {id: 5, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652488157/avatars/Flat_Icons_1_uuc6yy.svg"},
    {id: 6, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652487973/avatars/Flat_Icons_6_e8ddpp.svg"},
    {id: 7, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652487973/avatars/Flat_Icons_2_ogiqro.svg"},
    {id: 8, src:"https://res.cloudinary.com/mookie-admin/image/upload/v1652488117/avatars/Flat_Icons_wkslkv.svg"},
];

export const doOCR = async (worker,setOcr) => {
  await worker.load();
  await worker.loadLanguage('eng');
  await worker.initialize('eng');
  const { data: { text } } = await worker.recognize('https://tesseract.projectnaptha.com/img/eng_bw.png');
  setOcr(text);
};