// actions/sseAction.js

import { postApi,getApi,putApi,deleteApi,patchApi } from "../../utils/reqClient";
import handleError from "../../utils/handleError";

// Action types
export const GET_NOTIFICATION_BY_ID_SUCCESS = "GET_NOTIFICATION_BY_ID_SUCCESS";
export const GET_NOTIFICATION_BY_ID_FAILURE = "GET_NOTIFICATION_BY_ID_FAILURE";

export const GET_NOTIFICATIONS_BY_RECIPIENT_SUCCESS = "GET_NOTIFICATIONS_BY_RECIPIENT_SUCCESS";
export const GET_NOTIFICATIONS_BY_RECIPIENT_FAILURE = "GET_NOTIFICATIONS_BY_RECIPIENT_FAILURE";

export const UPDATE_NOTIFICATION_STATUS_SUCCESS = "UPDATE_NOTIFICATION_STATUS_SUCCESS";
export const UPDATE_NOTIFICATION_STATUS_FAILURE = "UPDATE_NOTIFICATION_STATUS_FAILURE";

export const SSE_EVENT_RECEIVED = "SSE_EVENT_RECEIVED";
export const FETCH_NOTIFICATION_MESSAGE = "FETCH_NOTIFICATION_MESSAGE";

export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ";

export const sseEventReceived = (eventData) => async (dispatch) => {
    try {
      // Dispatch SSE_EVENT_RECEIVED action
      dispatch({
        type: SSE_EVENT_RECEIVED,
        payload: eventData,
      });

      // Check if eventData contains an ID (assuming the ID key is 'id')
      if (eventData.id) {
        // Dispatch getNotificationById action using the ID from eventData
        dispatch(getNotificationById(eventData.id));
      }
    } catch (error) {
      //handleError(error);
    }
};

// Get a single notification by ID
export const getNotificationById = (notificationId) => async (dispatch) => {
  try {
    const response = await getApi(`others/getNotificationById?notificationId=${notificationId}`);
    if (response.data.success === true) {
      const { data } = response.data;
      dispatch({
        type: GET_NOTIFICATION_BY_ID_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: GET_NOTIFICATION_BY_ID_FAILURE });
    }
  } catch (error) {
    //handleError(error);
    dispatch({ type: GET_NOTIFICATION_BY_ID_FAILURE });
  }
};

// Get notifications by recipient
export const getNotificationsByRecipient = (recipientId, page=1, pageSize = 10) => async (dispatch) => {
  try {
    const response = await getApi(`others/getNotificationsByRecipient?recipient=${recipientId}&page=${page}&pageSize=${pageSize}`);
    const { data } = response.data;

    if (response.data.success === true) {
      const { notifications, totalCount, pendingTotalCount, hasNextPage } = data;

      dispatch({
        type: GET_NOTIFICATIONS_BY_RECIPIENT_SUCCESS,
        payload: { data: { totalCount, pendingTotalCount, notifications, hasNextPage } },
      });
      return hasNextPage;

    } else {
      // Log the specific error message if available
      console.error('Error fetching notifications:', data.message || 'Unknown error');
      dispatch({ type: GET_NOTIFICATIONS_BY_RECIPIENT_FAILURE });
      return false;
    }
  } catch (error) {
    console.error('Error fetching notifications:', error);
    dispatch({ type: GET_NOTIFICATIONS_BY_RECIPIENT_FAILURE });
    return false;
  }
};

// Update notification status
export const updateNotificationStatus = (notificationId, status) => async (dispatch) => {
  try {
    const response = await patchApi(`others/updateNotificationStatus`, { notificationId, status });
    if (response.data.success === true) {
      dispatch({
        type: UPDATE_NOTIFICATION_STATUS_SUCCESS,
        payload: { id: notificationId, status },
      });
    } else {
      dispatch({ type: UPDATE_NOTIFICATION_STATUS_FAILURE });
    }
  } catch (error) {
    handleError(error);
    dispatch({ type: UPDATE_NOTIFICATION_STATUS_FAILURE });
  }
};

// Update notification status
export const markAllAsRead = (recipientId) => async (dispatch) => {
  try {
    const response = await getApi(`others/mark-all-as-read?recipient=${recipientId}`);
    if (response.data.success === true) {
      dispatch({ type: MARK_ALL_AS_READ });
    }
  } catch (error) {
    handleError(error);
  }
};


const fetchNotificationMessage = async (messageId) => {
  try {
    const response = await getApi(`others/notification-messages/${messageId}`);
    if (response.data.success === true) {
      return response.data.data;
    } else return null;
  } catch (error) {
    console.error(error);
  }
};

