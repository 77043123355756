import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icons";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  render() {
    if (this.state.hasError) {
      return (
          <div style={{ padding: 0, fontFamily: 'Arial, sans-serif' }}>
              <section className='w-full m-a justify-center flex mt-8'>
                  <Icon id="mookie-icon" width="132px" height="32px"/>
              </section>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <main style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
                      <section style={{ maxWidth: '300px', width: '100%', padding: '0 20px' }}>
                          <section style={{ textAlign: 'center' }}>
                              <h2 style={{ fontSize: '21px', marginBottom: '20px', color: '#333' }}>
                                  Something went wrong{" "}
                                  <span role="img" aria-label="broken">
                                      💔
                                  </span>
                              </h2>
                              <p style={{ fontSize: '16px', color: '#888', lineHeight: '22px' }}>
                                  refresh this page or check your internet connection <br />
                              </p>
                              <div style={{ marginTop: '20px' }}>
                                  <button
                                      style={{
                                          padding: '10px 20px',
                                          fontSize: '16px',
                                          color: '#fff',
                                          backgroundColor: '#C734E5',
                                          border: 'none',
                                          borderRadius: '4px',
                                          cursor: 'pointer'
                                      }}
                                      onClick={() => window.location.replace("/")}
                                  >
                                      GO HOME
                                  </button>
                              </div>
                          </section>
                      </section>
                  </main>
              </div>
          </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.elementType.isRequired
};

export default ErrorBoundary;
