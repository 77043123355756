import { 
  GET_ALL_BOOKMAKERS,GET_ALL_BOOKINGS,ADD_BOOKING,
  PRE_SAVE_BOOKING,CANCEL_SAVE_BOOKING,REMOVE_BOOKING,
  REMOVE_BOOKING2,FAIL_CONVERT_BOOKING,SUCCESS_CONVERT_BOOKING,
  LOAD_CONVERT_BOOKING,FAILED_ADD_BOOKING_VIA_OCR ,
  SORT_BOOKINGS,ADD_BOOKING2,IS_BOOKING_LOADING_FALSE,
  IS_BOOKING_LOADING_TRUE,FETCH_BOOKING_DETAILS,
  UPDATE_BOOKING_STATUS,ACTIVE_COUNT,GET_GAMES_ANALYTICS,GET_BOOKING_ANALYTICS,
  GET_COMMUNITY_BOOKING_ANALYTICS,GET_COMMUNITY_GAMES_ANALYTICS, PRE_SAVE_EVENTS,
  LOAD_REFRESH_BOOKING, SUCCESS_REFRESH_BOOKING, FAIL_REFRESH_BOOKING, MANAGE_ACTIVE_BOOKING
} from "../types/bookingTypes";
import { LOGOUT_USER } from "../types/authTypes";
import { deepEqual } from "../../helpers/utils";

const INITIAL_STATE = {
  bookmakers:[],
  bookings:[],
  singleBooking: [],
  bookingList:{
    list: [],
    pageInfo: {}
  },
  isLoading: false,
  presave: {},
  activeCount: 0,
  analytics: { 
      bookings: {}, 
      games: {}
  },
  community_analytics: { 
      bookings: {}, 
      games: {}
  },
  presavedEvents: [],
  new: 0,
};

const bookingReducer = (state = INITIAL_STATE, action) => {
  
  const addBooking = (state, action) => {
    const oldBookingList = state.bookings.slice(0);
    const updatedBookingList = oldBookingList.filter(item => item.loading !== "addbooking");
    return [...updatedBookingList, action.payload]
  };

  const removeBooking = (state, action) => {
    const oldBookingList = state.bookings.slice(0);
    const updatedBookingList = oldBookingList.filter(item => item.id !== action.payload);
    return updatedBookingList;
  };
  const removeBooking2 = (state, action) => {
    const oldBookingList = state.bookings.slice(0);
    const updatedBookingList = oldBookingList.filter(item => item.loading === "addbooking");
    return updatedBookingList;
  };

  const completeConvert = (state,action) => {
    const oldBookingList1 = state.bookings.slice(0).filter(item => item.type !== "convert");
    const oldBookingList = oldBookingList1.filter(item => item.reference !== action.reference);
    oldBookingList.push(action.payload);
    return oldBookingList;
  }

  const completeRefresh = (state,action) => {
    const initialList = state.bookings.filter(item => item.reference !== action.payload.reference);
    initialList.push(action.payload);
    return initialList;
  }

  const failConvert = (state,action) => {
    const oldBookingList = state.bookings.slice(0).filter(item => item.type !== "convert");
    return oldBookingList;
  }
  const updateBooking = (state,action) => {
      const arr = state.bookingList.list.slice(0);
      const index = state.bookingList.list.findIndex(entry => entry.reference === action.payload.reference);
      arr[index] = {...arr[index], status: "INITIATED"};
      return arr;
  }

  switch (action.type) {
    case GET_ALL_BOOKMAKERS:
      return {
        ...state,
        bookmakers: action.payload,
      };
    case GET_ALL_BOOKINGS:
      return {
        ...state,
        bookingList: {list: action.payload,pageInfo: action.info},
      };
    case SORT_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      };
    case ADD_BOOKING:
      return {
        ...state,
        bookings: addBooking(state, action),
        presave: {},
        new: action?.payload?.reference || 0
      };
      // this is for the home page active bookings fetch. desgined to rewrite the bookings list array 
    case ADD_BOOKING2:
      return {
        ...state,
        bookings: action.payload,
      };
    case UPDATE_BOOKING_STATUS: 
      return {
        ...state,
        bookingList: {
          ...state.bookingList,
          list: updateBooking(state,action)
        },
        new: action.payload?.reference
      };
    case MANAGE_ACTIVE_BOOKING: 
      return {
        ...state,
        new: action.payload
      };
    case REMOVE_BOOKING:
      return {
        ...state,
        bookings: removeBooking(state, action),
      };
    case FETCH_BOOKING_DETAILS:
      return {
        ...state,
        singleBooking: [...state.singleBooking, action.payload]
      };
    case REMOVE_BOOKING2:
      return {
        ...state,
        bookings: removeBooking2(state,action),
      };
    case PRE_SAVE_BOOKING:
      return {
        ...state,
        presave: action.payload,
      };
    case PRE_SAVE_EVENTS:
      return {
        ...state,
        presavedEvents: action.payload
      };
    case CANCEL_SAVE_BOOKING:
      return {
        ...state,
        presave: {},
      };
    case LOAD_CONVERT_BOOKING:
      return {
        ...state,
        bookings: [...state.bookings,{reference: action.payload.bookmaker_id, type:"convert", id: action.payload.bookmaker_id, loading: "true", converting_to: action.payload.bookmaker_to_slug}]
      };
    case SUCCESS_CONVERT_BOOKING:
      return {
        ...state,
        bookings: completeConvert(state, action),
        new: action.payload?.reference
      };
    case FAIL_CONVERT_BOOKING:
      return {
        ...state,
        bookings: failConvert(state, action),
      };
    case FAILED_ADD_BOOKING_VIA_OCR:
      return {
        ...state,
        bookings: [...state.bookings,{loading: "addbooking"}],
      };
    case IS_BOOKING_LOADING_FALSE:
      return {
        ...state,
        isLoading: false
      };
    case IS_BOOKING_LOADING_TRUE:
      return {
        ...state,
        isLoading: true
      };
    case ACTIVE_COUNT:
      return {
        ...state,
        activeCount: action.payload
      };
    case GET_BOOKING_ANALYTICS:
      return {
        ...state,
        analytics: { ...state.analytics, bookings: action.payload }
      };
    case GET_COMMUNITY_BOOKING_ANALYTICS:
      return {
        ...state,
        community_analytics: { ...state.community_analytics, bookings: action.payload }
      };
    case GET_GAMES_ANALYTICS:
      return {
        ...state,
        analytics: { ...state.analytics, games: action.payload }
      };
    case GET_COMMUNITY_GAMES_ANALYTICS:
      return {
        ...state,
        community_analytics: { ...state.community_analytics, games: action.payload }
      };
    case SUCCESS_REFRESH_BOOKING:
      return {
        ...state,
        bookings: completeRefresh(state, action),
        new: action?.payload?.reference
      };
    case LOAD_REFRESH_BOOKING:
      return {
        ...state,
        bookings: state.bookings.map(booking =>
          booking.reference === action.payload.reference ? { ...booking, loading: "refresh_booking" } : booking
        ),
      };
    case FAIL_REFRESH_BOOKING:
      return {
        ...state,
        bookings: state.bookings.map(booking =>
          booking.reference === action.payload.reference ? { ...booking, loading: null } : booking
        ),
        new: 0
      };      
    case LOGOUT_USER:
      return {
        ...state,
        bookings:[],
        singleBooking: [],
        bookingList:{
          list: [],
          pageInfo: {}
        },
        isLoading: false,
        presave: {},
        activeCount: 0,
        analytics: { 
            bookings: {}, 
            games: {}
        },
        community_analytics: { 
            bookings: {}, 
            games: {}
        },
        new: 0,
      };
    default:
      return state;
  }
};

export default bookingReducer;