import Cookies from "js-cookie";

export const setCookie = (cname, cvalue) => {
  Cookies.set(cname, cvalue);
};

export const removeAllCookies = () => {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};
export const getCookie = (cname) => Cookies.get(cname);

export const getCookieJSON = (cname) => {
  return Cookies.getJSON(cname);
};

export const removeCookie = (cname) => {
  Cookies.remove(cname);
};

export const setLocalStorage = (cname, cvalue) => {
  localStorage.setItem(cname, cvalue);
};

export const setLocalStorageJSON = (cname, cvalue) => {
  localStorage.setItem(cname, JSON.stringify(cvalue));
};

export const removeLocalStorage = (cname) => {
  localStorage.removeItem(cname);
};
export const getLocalStorageItem = (cname) => localStorage.getItem(cname);

export const getJSONLocalStorage = (cname) =>
  JSON.parse(localStorage.getItem(cname));
